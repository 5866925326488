<template>
  <v-alert v-model="value.active" class="alert" outlined :color="value.color">
    {{ value.message }}
    <template v-slot:append>
      <v-icon :color="value.color" class="pl-4"> {{ value.icon }} </v-icon>
    </template>
  </v-alert>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class TheAlert extends Vue {
  @Prop(Object) value: any;
}
</script>
<style lang="sass">
.alert
  right: 1.5rem
  position: fixed !important
  z-index: 600 !important
  border: 1px solid !important
  border-radius: 42px !important
  width: 340px !important
  font-size: 12px
  font-weight: 700
  height: 70px !important
  text-align: center
  align-items: center
  justify-content: center
  display: flex
  margin: 16px
  background: var(--v-white-base) !important
  @media(max-width: 600px)
    margin: 0
    bottom: 16px
    z-index: 999
    left:0
    right:0
    margin-left: auto
    margin-right: auto
</style>
