<template>
  <v-dialog :value="loadingStatus" persistent max-width="233px" class="white">
    <v-row class="white loading__modal">
      <v-col cols="12" class="pt-8 pb-7 d-flex justify-center">
        <v-img src="@/assets/loadingWhite.svg" max-width="66px" />
      </v-col>
      <v-col cols="12" class="text-center">
        <p class="primary--text loading__text mb-0 pb-5">
          {{ $t("misc.loading") }}
        </p>
      </v-col>
    </v-row>
  </v-dialog>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { State } from "vuex-class";
@Component
export default class LoadingModal extends Vue {
  @State("loadingDialog") loadingStatus: any;
}
</script>
<style lang="sass">
.loading__
  &text
    font-weight: 700
    font-size: 20px
  &modal
    border-radius: 15px
</style>
