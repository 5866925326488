import { api } from "@/api";
import { AxiosResponse } from "axios";
import { PayloadWhitePaperItems, PayloadSuccessCase } from "../types";

class API {
  static getWhitePapers(
    payload: PayloadWhitePaperItems
  ): Promise<AxiosResponse> {
    return api.get("/post/white-paper", { params: payload });
  }
  static getSuccessCase(payload: PayloadSuccessCase): Promise<AxiosResponse> {
    return api.get("/post/success-case", { params: payload });
  }
  static putVisibilitySuccessCase(payload: any): Promise<AxiosResponse> {
    return api.put(
      `/post/success-case/prioritizate/${payload.postId}/${payload.status}`,
      payload
    );
  }
}

export default API;
