<template>
  <div>
    <label
      :class="'body-2 font-weight-bold gray70--text d-flex ' + labelPosition"
      :for="idElement"
      v-if="labelText"
    >
      {{ labelText }}
    </label>
    <v-textarea
      v-on="$listeners"
      v-bind="defaultConfigs"
      :id="idElement"
      class="base-form-input"
    />
  </div>
</template>
<script lang="ts">
const defaultProp = {
  outlined: true,
  class: "mt-2 caption",
  dense: true,
  "background-color": "white",
};
import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class BaseFormTextArea extends Vue {
  @Prop(String) idElement: string;
  @Prop(String) labelText: string;
  @Prop({ default: "justify-start" }) labelPosition: string;
  get defaultConfigs(): any {
    return {
      ...this.$attrs,
      ...this.$props,
      ...defaultProp,
    };
  }
}
</script>
<style lang="sass"></style>
