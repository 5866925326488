import App from "@/views/App.vue";

export default [
  {
    path: "",
    name: "chat",
    component: App,
    redirect: { name: "chatHome" },
    children: [
      {
        path: "/chat/:id?",
        name: "chatHome",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/ChatList.vue"),
        meta: {
          displayName: "Conversas - Exclusive Lounge",
          requiresAuth: false,
        },
      },
    ],
  },
];
