<template>
  <v-autocomplete
    v-on="$listeners"
    v-bind="[$attrs, $props]"
    :items="items"
    :loading="loading"
    :search-input.sync="filter"
    hide-details
    hide-selected
    clearable
    append-outer-icon="fa-search"
    append-inner=""
    class="base-autocomplete"
    outlined
    dense
    item-text="name"
    item-value="id"
    :return-object="true"
    :cache-items="true"
    @change="resetFilter"
  >
    <template v-slot:selection="{ attr, on, item, selected }">
      <v-chip
        v-bind="attr"
        :input-value="selected"
        color="gray30"
        class="black--text base-autocomplete__chip"
        v-on="on"
      >
        <span v-text="item.name"></span>
      </v-chip>
    </template>
    <template v-slot:item="{ item }">
      <div class="d-inline-flex py-2 align-center">
        <img :src="item.profileImage" :class="borderClass(item)" />
        <div class="pl-2 align-center">
          <p
            class="gray70--text body-2 font-weight-bold mb-0"
            :class="item.hasMedal ? 'd-inline-flex' : ''"
          >
            {{ item.name }}
          </p>
          <img src="../../assets/medal.svg" class="pl-1" v-if="item.hasMedal" />
          <p class="black--text caption mb-0" v-if="item.role !== 'MARKETING'">
            {{ item.companyName }}
          </p>
        </div>
      </div>
    </template>
  </v-autocomplete>
</template>
<script lang="ts">
import Component, { mixins } from "vue-class-component";
import { ActionMixin } from "../../mixins/ActionMixin";
import { Action } from "vuex-class";
import { Event } from "@/types";
import { Prop, Watch } from "vue-property-decorator";
import i18n from "@/plugins/i18n";
import _ from "lodash";
@Component
export default class BaseAutocompleteUser extends mixins(ActionMixin) {
  @Prop(Array) currentEvent: any;
  @Prop(String) currentPublic: string;
  @Prop(Array) currentCommunity: any;
  @Prop(String) roles: string;
  @Prop() currentItem: any;
  @Action("post/requestUsersMark") requestUsersMark: any;
  publicItems = [
    {
      name: i18n.t("modal.modalEventsInPostAll"),
      id: "ALL",
    },
    {
      name: i18n.t("modal.modalEventsInPostClevels"),
      id: "C_LEVELS",
    },
    {
      name: i18n.t("modal.modalEventsInPostPartners"),
      id: "PARTNERS",
    },
  ];
  items: any[] = [];
  searchWord = "";
  loading = false;
  filter = "";
  created(): void {
    this.items = this.currentItem
      ? _.isArray(this.currentItem)
        ? this.currentItem
        : [this.currentItem]
      : [];
  }
  async updateMentionList(text: any): Promise<void> {
    const payload = {
      nameFilter: text,
      eventsId: this.currentEvent.reduce(
        (string: string, event: Event, index: number) => {
          if (index === this.currentEvent.length - 1)
            string += String(event.id);
          else if (event.id || event.id === 0) string += event.id + ",";
          return string;
        },
        ""
      ),
      communitiesId: this.currentCommunity.reduce(
        (string: string, community: Event, index: number) => {
          if (index === this.currentCommunity.length - 1)
            string += String(community.id);
          else if (community.id || community.id === 0)
            string += community.id + ",";
          return string;
        },
        ""
      ),
      roles: this.roles,
    };
    this.loading = true;
    await this.withLoading(async () => {
      await this.requestUsersMark(payload)
        .then((data: any) => {
          this.items = data;
          this.$emit("list", data);
        })
        .finally(() => {
          this.loading = false;
        });
    });
  }
  borderClass(item: any): string {
    return item.role === "PARTICIPANT"
      ? "user-border-autocomplete user-border-autocomplete__avatar-green"
      : item.role === "EXECUTIVE"
      ? "user-border-autocomplete user-border-autocomplete__avatar-red"
      : item.role === "EQUIPE"
      ? "user-border-autocomplete user-border-autocomplete__avatar-black"
      : item.role === "PARTNER"
      ? "user-border-autocomplete user-border-autocomplete__avatar-blue"
      : "user-border-autocomplete";
  }
  resetFilter(): void {
    if (this.roles === "MARKETING,FOUR_NETWORK") this.filter = "";
  }
  @Watch("filter", { immediate: true, deep: true })
  filterChanged(newVal: any, oldVal: any) {
    if (newVal || (oldVal && newVal !== oldVal)) this.updateMentionList(newVal);
  }
}
</script>
<style lang="sass">
.v-text-field--outlined.base-autocomplete fieldset, .v-text-field.base-autocomplete .v-input__control .v-input__slot
  border: none
.base-autocomplete
  .v-text-field__details
    display: none !important
  .v-input__append-inner
    display: none !important
  .v-label--active
    display: none !important
  .v-input__slot
    padding: 0 8px !important
  &__chip
    border-radius: 5px !important
</style>
