<template>
  <v-text-field
    v-bind="defaultConfigs"
    v-on="$listeners"
    append-outer-icon="fa-search"
    class="base-filter"
    clearable
    clear-icon="fa-regular fa-circle-xmark"
    :autofocus="
      $vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? false : isAutoFocus
    "
  />
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class BaseFilter extends Vue {
  @Prop({ default: "gray15" }) colorInput: string;
  @Prop({ default: true }) isAutoFocus: string;
  get defaultProp() {
    return {
      outlined: true,
      dense: true,
      "background-color": this.colorInput,
    };
  }
  get defaultConfigs(): any {
    return {
      ...this.$attrs,
      ...this.$props,
      ...this.defaultProp,
    };
  }
}
</script>
<style lang="sass">
.v-text-field--outlined.base-filter fieldset, .v-text-field.base-filter .v-input__control .v-input__slot
  border: 0.5px solid var(--v-gray30-base)
  border-radius: 16px
.v-input__append-inner .v-input__icon--append i
  color:  var(--v-gray50-base)
  width: 20px !important
  height: 20px !important
  padding-top: 5px
.base-filter
  .v-text-field__details
    display: none !important
.v-input__icon--clear, .v-input__icon--append-outer
  margin-top: 5px !important
.v-input__append-inner > .v-input__icon--clear > button
  color: #BBBBBB !important
</style>
