<template>
  <v-navigation-drawer
    app
    :mini-variant.sync="mini"
    class="white"
    :width="240"
    v-bind="[$attrs, $props]"
    v-model="drawer"
    style="z-index: 999"
    mini-variant-width="83"
  >
    <v-col cols="12" class="d-flex px-0 justify-center py-5">
      <img
        src="@/assets/exclusiveLounge.svg"
        width="45%"
        class="the-sidebar__img"
        @click="redirectTimeline"
      />
    </v-col>
    <!-- <div class="d-flex align-center base-div justify-center">
      <v-switch
        v-model="$vuetify.theme.dark"
        class="pa-0 ma-0 base-switch"
        inset
        hide-details
        color="white"
        :append-icon="
          $vuetify.theme.dark ? 'fa-solid fa-moon' : 'fa-solid fa-sun'
        "
        @change="updateTheme"
      />
    </div> -->
    <v-list dense class="pb-6">
      <v-list-item
        :key="key"
        v-for="(item, key) of generalItems"
        :class="classMiniSidebar"
      >
        <v-row>
          <v-col cols="12" class="py-0">
            <SidebarItem
              :mini="mini"
              :item="item"
              @click="updateSelectedItem(item)"
              :class="backgroundSelectedItem(item)"
              :selected="isSeletecItem(item)"
            />
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>
<script lang="ts">
import i18n from "@/plugins/i18n";
import { TranslateResult } from "vue-i18n";
import { Prop, Watch } from "vue-property-decorator";
import { Action } from "vuex-class";
import Component, { mixins } from "vue-class-component";
import { ActionMixin } from "../mixins/ActionMixin";
type sidebar_items = {
  image: any;
  imageSelected: any;
  moduleName: string;
  name: string | TranslateResult;
  route: string;
};
@Component
export default class TheSidebar extends mixins(ActionMixin) {
  @Prop({ default: false }) toggle: boolean;
  @Prop({ default: false }) mini: boolean;
  selected = null;
  generalItems = [
    {
      imageSelected: require("@/assets/unionTimeline.svg"),
      image: require("@/assets/unionTimelineGray.svg"),
      moduleName: "timeline",
      name: "Timeline",
      route: "timelineHome",
    },
    {
      imageSelected: require("@/assets/purple_news.svg"),
      image: require("@/assets/grey_news.svg"),
      moduleName: "readingList",
      name: i18n.t("readingList.readingList"),
      route: "readingListHome",
    },
    {
      imageSelected: require("@/assets/ic_notificacoes_purple.svg"),
      image: require("@/assets/ic_notificacoes.svg"),
      moduleName: "notification",
      name: i18n.t("notification.title"),
      route: "notificationHome",
    },
    {
      imageSelected: require("@/assets/mensagem_in_off_purple.svg"),
      image: require("@/assets/mensagem_white.svg"),
      moduleName: "chat",
      name: i18n.t("chat.title"),
      route: "chatHome",
    },
    {
      imageSelected: require("@/assets/configurationOptionsPurple.svg"),
      image: require("@/assets/configurationOptions.svg"),
      moduleName: "settings",
      name: i18n.t("notification.config"),
      route: "settingsHome",
    },
    {
      imageSelected: require("@/assets/participantesMini.svg"),
      image: require("@/assets/participantesTimeline.svg"),
      moduleName: "profile",
      name: i18n.t("misc.profile"),
      route: "profileHome",
    },
  ];
  drawer = false;
  @Action("profile/requestUpdateTheme") requestUpdateTheme: any;
  get classMiniSidebar(): string {
    return this.mini ? "px-4" : "px-2";
  }
  mounted(): void {
    let currentItem: any;
    this.generalItems.forEach((item: sidebar_items) => {
      let route = this.$route.name;
      if (route?.includes(item.moduleName)) currentItem = item;
    });
    this.selected = currentItem;
  }
  updateMiniSidebar(): void {
    this.mini = !this.mini;
  }
  isSeletecItem(item: sidebar_items): boolean {
    return this.selected == item;
  }
  updateSelectedItem(item: any): void {
    this.selected = this.selected === item ? null : item;
    if (item.moduleName) this.$router.push({ name: item.moduleName });
  }
  backgroundSelectedItem(item: sidebar_items): string {
    return this.isSeletecItem(item)
      ? "white purple100--text"
      : "white gray70--text";
  }
  async updateTheme(item: boolean): Promise<void> {
    const payload = {
      theme: item ? "DARK" : "LIGHT",
    };
    await this.withLoading(async () => {
      await this.requestUpdateTheme(payload);
    });
  }
  redirectTimeline(): void {
    if (this.$route.name !== "imelineHome")
      this.$router.push({ name: "timelineHome" });
  }
  @Watch("toggle", { immediate: true }) onItemChanged(): void {
    this.drawer = this.toggle;
  }
}
</script>
<style lang="sass" scoped>
.v-navigation-drawer
  box-shadow: 6px 4px 12px 2px rgba(0, 0, 0, 0.1)
  padding-left: env(safe-area-inset-left, 5px)
  padding-left: const(safe-area-inset-left, 5px)
.the-sidebar__img
  cursor: pointer
</style>
