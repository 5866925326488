export const TOOLBAR_BUTTONS = [
  {
    route: "timelineHome",
    image: require("@/assets/unionTimelineGray.svg"),
    imageSelected: require("@/assets/unionTimeline.svg"),
  },
  {
    route: "readingListHome",
    image: require("@/assets/grey_news.svg"),
    imageSelected: require("@/assets/purple_news.svg"),
  },
  {
    route: "notificationHome",
    image: require("@/assets/ic_notificacoes.svg"),
    imageSelected: require("@/assets/ic_notificacoes_purple.svg"),
  },
  {
    route: "chatHome",
    image: require("@/assets/mensagem_white.svg"),
    imageSelected: require("@/assets/mensagem_in_off_purple.svg"),
  },
  {
    route: "settingsHome",
    image: require("@/assets/configurationOptions.svg"),
    imageSelected: require("@/assets/configurationOptionsPurple.svg"),
  },
  {
    route: "profileHome",
    image: require("@/assets/participantesTimeline.svg"),
    imageSelected: require("@/assets/participantesMini.svg"),
  },
];
