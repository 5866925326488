import { api } from "@/api";
import { AxiosResponse } from "axios";
import { PayloadStatusCommunity } from "../types";
class API {
  static getCurrentCommunity(payload: number): Promise<AxiosResponse> {
    return api.get(`/community/${payload}`);
  }
  static getFeedCommunity(payload: any): Promise<AxiosResponse> {
    return api.get(`/community/${payload.communityId}/posts`, {
      params: payload.params,
      headers: payload.headers,
    });
  }
  static getCommunities(): Promise<AxiosResponse> {
    return api.get("/community/list");
  }
  static putStatusCommunity(
    payload: PayloadStatusCommunity
  ): Promise<AxiosResponse> {
    return api.put("/community/profile/status", payload);
  }
  static getIsNewCommunity(): Promise<AxiosResponse> {
    return api.get("/community/news");
  }
  static putNotificationCommunity(): Promise<AxiosResponse> {
    return api.put("/community/news");
  }
  static getCommunitiesActives(): Promise<AxiosResponse> {
    return api.get("/community/list/actives");
  }
  static getCommunityPending(): Promise<AxiosResponse> {
    return api.get("/community/pending");
  }
}

export default API;
