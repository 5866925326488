import { Commit } from "vuex";
import API from "../api";
import {
  PayloadAdvertStatus,
  PayloadStatusDenunciation,
  PayloadNewInterest,
  PayloadNewInterestApprove,
  PayloadDeleteSuggestion,
  PayloadAdvert,
  PayloadChart,
  PayloadChartFourNetwork,
  PayloadExportChartExclusive,
  PayloadExportChartFourNetwork,
  PayloadExportChartLounge,
  PayloadPermissionUser,
  PayloadMetricsPost,
  OptionsMetricsPost,
} from "../types";
export default {
  namespaced: true,
  state: {
    lastPagePost: 1,
    optionsMetricsPost: {
      groupBy: [],
      groupDesc: [],
      itemsPerPage: 8,
      multiSort: false,
      mustSort: false,
      page: 1,
      sortBy: [],
      sortDesc: [],
    },
    payloadMetricsPost: {
      filter: "",
      scheduleDate: [],
    },
    tabMetricsPost: "",
  },
  mutations: {
    setLastPagePost(state: any, payload: number) {
      state.lastPagePost = payload;
    },
    setOptionsMetricsPost(state: any, payload: OptionsMetricsPost) {
      state.optionsMetricsPost = payload;
    },
    setPayloadMetricsPost(state: any, payload: PayloadMetricsPost) {
      state.payloadMetricsPost = payload;
    },
    setTabMetricsPost(state: any, payload: PayloadMetricsPost) {
      state.tabMetricsPost = payload;
    },
  },
  actions: {
    async requestAdvertPending(
      context: any,
      payload: PayloadAdvert
    ): Promise<any> {
      const { data } = await API.getAdvertPending(payload);
      return data;
    },
    async requestAdvertApproved(
      context: any,
      payload: PayloadAdvert
    ): Promise<any> {
      const { data } = await API.getAdvertApproved(payload);
      return data;
    },
    async requestAdvertDisapproved(
      context: any,
      payload: PayloadAdvert
    ): Promise<any> {
      const { data } = await API.getAdvertDisapproved(payload);
      return data;
    },
    async requestAdvertExcluded(
      context: any,
      payload: PayloadAdvert
    ): Promise<any> {
      const { data } = await API.getAdvertExcluded(payload);
      return data;
    },
    async requestAdvertStatus(
      context: any,
      payload: PayloadAdvertStatus
    ): Promise<any> {
      const { data } = await API.putAdvertStatus(payload);
      return data;
    },
    async requestDenunciationAdverts(): Promise<any> {
      const { data } = await API.getDenunciationAdverts();
      return data;
    },
    async requestDenunciationComments(): Promise<any> {
      const { data } = await API.getDenunciationComments();
      return data;
    },
    async requestDenunciationPosts(): Promise<any> {
      const { data } = await API.getDenunciationPosts();
      return data;
    },
    async requestInactivationUsers(): Promise<any> {
      const { data } = await API.getInactivationUsers();
      return data;
    },
    async requestInactivationComments(
      context: any,
      payload: any
    ): Promise<any> {
      const { data } = await API.getInactivationComments(payload);
      return data;
    },
    async requestInactivationPosts(context: any, payload: any): Promise<any> {
      const { data } = await API.getInactivationPosts(payload);
      return data;
    },
    async requestReactivateUser(context: any, payload: number) {
      const { data } = await API.postReactivateUser(payload);
      return data;
    },
    async requestMetrics(): Promise<any> {
      const { data } = await API.getMetrics();
      return data;
    },
    async requestRanking(context: any, payload: any) {
      const { data } = await API.getRanking(payload);
      return data;
    },
    async requestStatusDenunciation(
      context: any,
      payload: PayloadStatusDenunciation
    ) {
      const { data } = await API.putStatusDenunciation(payload);
      return data;
    },
    async requestPostUser(context: any, payload: any) {
      const { data } = await API.getPostUser(payload);
      return data;
    },
    async requestCallToActionPost(context: any, payload: any) {
      const { data } = await API.getCallToActionPost(payload);
      return data;
    },
    async requestInteractionPost(context: any, payload: any) {
      const { data } = await API.getInteractionPost(payload);
      return data;
    },
    async requestMetricsCompany(): Promise<any> {
      const { data } = await API.getMetricsCompany();
      return data;
    },
    async requestRankingCompany(context: any, payload: any) {
      const { data } = await API.getRankingCompany(payload);
      return data;
    },
    async requestAdvertDeleted(): Promise<any> {
      const { data } = await API.getAdvertDeleted();
      return data;
    },
    async requestInterest(context: any, payload: [PayloadNewInterest]) {
      const { data } = await API.postNewInterest(payload);
      return data;
    },
    updateLastPagePost(
      {
        commit,
      }: {
        commit: Commit;
      },
      payload: number
    ): void {
      commit("setLastPagePost", payload);
    },
    async requestFileMetrics(context: any, payload: number) {
      const { data } = await API.getFileMetrics(payload);
      return data;
    },
    async requestFileInteraction(context: any, payload: number) {
      const { data } = await API.getFileInteraction(payload);
      return data;
    },
    async requestFileInterestExclusive() {
      const { data } = await API.getFileInterestExclusive();
      return data;
    },
    async requestFileMostInterestExclusive() {
      const { data } = await API.getFileMostInterestExclusive();
      return data;
    },
    async requestFileMostInterestClevelsExclusive() {
      const { data } = await API.getFileMostInterestClevelsExclusive();
      return data;
    },
    async requestFileInteractedInterestExclusive() {
      const { data } = await API.getFileInteractedInterestExclusive();
      return data;
    },
    async requestFileInterestCompany() {
      const { data } = await API.getFileInterestCompany();
      return data;
    },
    async requestFileMostInterestCompany() {
      const { data } = await API.getFileMostInterestCompany();
      return data;
    },
    async requestFileInteractedInterestCompany() {
      const { data } = await API.getFileInteractedInterestCompany();
      return data;
    },
    async requestFileInteractedInterestCompanyExclusive() {
      const { data } = await API.getFileInteractedInterestCompanyExclusive();
      return data;
    },
    async requestFileInteractedInterestClevelsExclusive() {
      const { data } = await API.getFileInteractedInterestClevelsExclusive();
      return data;
    },
    async requestFileInteractedHobbiesCompanyClevelsExclusive() {
      const { data } =
        await API.getFileInteractedHobbiesCompanyClevelsExclusive();
      return data;
    },
    async requestUserClevel(context: any, payload: any): Promise<any> {
      const { data } = await API.getUserClevel(payload);
      return data;
    },
    async requestUserMarketing(context: any, payload: any): Promise<any> {
      const { data } = await API.getUserMarketing(payload);
      return data;
    },
    async requestUpdateProfile(context: any, payload: string): Promise<any> {
      const { data } = await API.putUpdateProfile(payload);
      return data;
    },
    async requestInterestHobby(context: any, payload: any): Promise<any> {
      const { data } = await API.getInterestHobby(payload);
      return data;
    },
    async requestSuggestionInterestHobby(
      context: any,
      payload: any
    ): Promise<any> {
      const { data } = await API.getSuggestionInterestHobby(payload);
      return data;
    },
    async requestUpdateInterestHobby(context: any, payload: any): Promise<any> {
      const { data } = await API.putUpdateInterestHobby(payload);
      return data;
    },
    async requestInterestApprove(
      context: any,
      payload: PayloadNewInterestApprove
    ) {
      const { data } = await API.postNewInterestApprove(payload);
      return data;
    },
    async requestSuggestionDelete(
      context: any,
      payload: PayloadDeleteSuggestion
    ): Promise<any> {
      const { data } = await API.getDeleteSuggestion(payload);
      return data;
    },
    async requestEditStatusInterestHobby(
      context: any,
      payload: string
    ): Promise<any> {
      const { data } = await API.putStatusInterestHobby(payload);
      return data;
    },
    async requestPostSchedule(context: any, payload: any) {
      const { data } = await API.getPostSchedule(payload);
      return data;
    },
    async requestCategories(): Promise<any> {
      const { data } = await API.getCategories();
      return data;
    },
    async requestCommunities(context: any, payload: any): Promise<any> {
      const { data } = await API.getCommunities(payload);
      return data;
    },
    async requestCommunityMembers(context: any, payload: any): Promise<any> {
      const { data } = await API.getCommunityMembers(payload);
      return data;
    },
    async requestCommunity(context: any, payload: any): Promise<any> {
      const { data } = await API.getCommunity(payload);
      return data;
    },
    async requestCommunityEdit(context: any, payload: any): Promise<any> {
      const { data } = await API.putCommunity(payload);
      return data;
    },
    async requestCommunityQuota(context: any, payload: any): Promise<any> {
      const { data } = await API.getCommunityQuota(payload);
      return data;
    },
    async requestNewCommunityQuota(context: any, payload: any): Promise<any> {
      const { data } = await API.postCommunityQuota(payload);
      return data;
    },
    async requestDeleteCommunityQuota(
      context: any,
      payload: any
    ): Promise<any> {
      const { data } = await API.deleteCommunityQuota(payload);
      return data;
    },
    async requestAssignedBenefit(context: any, payload: any) {
      const { data } = await API.getAssignedBenefit(payload);
      return data;
    },
    async requestPostAssignedBenefit(context: any, payload: any) {
      const { data } = await API.postAssignedBenefit(payload);
      return data;
    },
    async requestFixedList(context: any, payload: any) {
      const { data } = await API.getFixedList(payload);
      return data;
    },
    async requestUpdateFixedList(context: any, payload: any) {
      const { data } = await API.postFixedList(payload);
      return data;
    },
    async requestChartExclusive(context: any, payload: PayloadChart) {
      const { data } = await API.getChartExclusive(payload);
      return data;
    },
    async requestChartFourNetwork(
      context: any,
      payload: PayloadChartFourNetwork
    ) {
      const { data } = await API.getChartFourNetwork(payload);
      return data;
    },
    async requestDebates(context: any, payload: any) {
      const { data } = await API.getDebates(payload);
      return data;
    },
    async requestStatusDebate(context: any, payload: any) {
      const { data } = await API.putStatusDebate(payload);
      return data;
    },
    async requestFixedTagsList(context: any, payload: any) {
      const { data } = await API.getFixedTags(payload);
      return data;
    },
    async requestNewFixedTag(context: any, payload: any) {
      const { data } = await API.postFixedTag(payload);
      return data;
    },
    async requestEditFixedTag(context: any, payload: any) {
      const { data } = await API.putFixedTag(payload);
      return data;
    },
    async requestFixedTag(context: any, payload: any) {
      const { data } = await API.getFixedTag(payload);
      return data;
    },
    async requestDeleteFixedTag(context: any, payload: any) {
      const { data } = await API.deleteFixedTag(payload);
      return data;
    },
    async requestViewsFixedTag(context: any, payload: any) {
      const { data } = await API.getViewsFixedTag(payload);
      return data;
    },
    async requestCompanyClick(context: any, payload: any) {
      const { data } = await API.putCompanyClick(payload);
      return data;
    },
    async requestMetricsLounge(context: any, payload: any) {
      const { data } = await API.getMetricsLounge(payload);
      return data;
    },
    async requestRankingLounge(context: any, payload: any) {
      const { data } = await API.getRankingLounge(payload);
      return data;
    },
    async requestPostReportLounge(context: any, payload: any) {
      const { data } = await API.getPostReportLounge(payload);
      return data;
    },
    async requestInactivationDebate(context: any, payload: any) {
      const { data } = await API.getInactivationDebate(payload);
      return data;
    },
    async requestExportChartExclusive(
      context: any,
      payload: PayloadExportChartExclusive
    ) {
      const { data } = await API.getExportChartExclusive(payload);
      return data;
    },
    async requestExportChartFourNetwork(
      context: any,
      payload: PayloadExportChartFourNetwork
    ) {
      const { data } = await API.getExportChartFourNetwork(payload);
      return data;
    },
    async requestExportChartLounge(
      context: any,
      payload: PayloadExportChartLounge
    ) {
      const { data } = await API.getExportChartLounge(payload);
      return data;
    },
    async requestPermissionsUser(context: any, payload: string) {
      const { data } = await API.getPermissionsUser(payload);
      return data;
    },
    async requestUpdatePermissionsUser(
      context: any,
      payload: PayloadPermissionUser
    ) {
      const { data } = await API.postPermissionsUser(payload);
      return data;
    },
    updateLastPayloadMetricsPost(
      {
        commit,
      }: {
        commit: Commit;
      },
      payload: PayloadMetricsPost
    ): void {
      commit("setPayloadMetricsPost", payload);
    },
    updateLastOptionsMetricsPost(
      {
        commit,
      }: {
        commit: Commit;
      },
      payload: OptionsMetricsPost
    ): void {
      commit("setOptionsMetricsPost", payload);
    },
    async requestPostColab(context: any, payload: any) {
      const { data } = await API.getPostColab(payload);
      return data;
    },
    updateLastTabMetricsPost(
      {
        commit,
      }: {
        commit: Commit;
      },
      payload: string
    ): void {
      commit("setTabMetricsPost", payload);
    },
  },
  modules: {},
};
