import Vue from "vue";
import VueQuillEditor from "vue-quill-editor";
// require styles
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import "quill-mention";
import "quill-mention/dist/quill.mention.css";

export default Vue.use(VueQuillEditor);
