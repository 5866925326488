<template>
  <fragment>
    <quill-editor
      v-on="$listeners"
      v-bind="[$attrs, $props]"
      ref="quillComponent"
      :options="editorOption"
      class="quill-editor"
      :class="classQuill"
      @change="updateMentionList"
    />
    <div
      v-if="classQuill"
      v-html="$t('post.limitCaracter')"
      class="redAlert--text body-2"
    />
  </fragment>
</template>
<script lang="ts">
import Component, { mixins } from "vue-class-component";
import { ActionMixin } from "../../mixins/ActionMixin";
import { Action } from "vuex-class";
import { Event } from "@/types";
import { Prop } from "vue-property-decorator";
import i18n from "@/plugins/i18n";
import "quill-paste-smart";
let items: any[] = [];
let searchWord = "";
@Component
export default class BaseQuillEditor extends mixins(ActionMixin) {
  @Prop(Array) currentEvent: any;
  @Prop(String) currentPublic: string;
  @Prop({ default: "" }) classQuill: string;
  @Prop(Array) currentCommunity: any;
  @Action("post/requestUsersMark") requestUsersMark: any;
  publicItems = [
    {
      name: i18n.t("modal.modalEventsInPostAll"),
      id: "ALL",
    },
    {
      name: i18n.t("modal.modalEventsInPostClevels"),
      id: "C_LEVELS",
    },
    {
      name: i18n.t("modal.modalEventsInPostPartners"),
      id: "PARTNERS",
    },
  ];
  editorOption = {
    modules: {
      toolbar: [
        ["bold", "italic", "underline", "strike"],
        ["blockquote", "code-block", "link", "image"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ align: [] }],
      ],
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ["@"],
        source: async function (
          searchTerm: any,
          renderItem: any,
          mentionChar: string
        ) {
          if (mentionChar === "@" && searchTerm.length !== 0) {
            searchWord = searchTerm;
            setTimeout(() => {
              renderItem(items, mentionChar);
            }, 500);
          }
        },
        renderItem: (item: any, mentionChar: any) => {
          if (mentionChar === "@") {
            const borderClass =
              item.role === "PARTICIPANT"
                ? "mention-border mention-border__avatar-green"
                : item.role === "EXECUTIVE"
                ? "mention-border mention-border__avatar-red"
                : item.role === "EQUIPE"
                ? "mention-border mention-border__avatar-black"
                : item.role === "PARTNER"
                ? "mention-border mention-border__avatar-blue"
                : "mention-border";
            return item.hasMedal
              ? `
                <div class="d-inline-flex py-2 align-center">
                  <img src="${item.profileImage}" class="${borderClass}" />
                  <div class="pl-2 align-center">
                    <p class="gray70--text body-2 font-weight-bold mb-0 d-inline-flex">
                      ${item.name}
                    </p>
                    <img src="${require("../../assets/medal.svg")}" class="pl-1" />
                    <p class="black--text caption mb-0">
                      ${item.companyName}
                    </p>
                  </div>
                </div>
              `
              : item.role === "MARKETING"
              ? `
                <div class="d-inline-flex py-2 align-center">
                  <img src="${item.profileImage}" class="${borderClass}" />
                  <div class="pl-2 align-center">
                    <p class="gray70--text body-2 font-weight-bold mb-0">
                      ${item.name}
                    </p>
                  </div>
                </div>
              `
              : `
                <div class="d-inline-flex py-2 align-center">
                  <img src="${item.profileImage}" class="${borderClass}" />
                  <div class="pl-2 align-center">
                    <p class="gray70--text body-2 font-weight-bold mb-0">
                      ${item.name}
                    </p>
                    <p class="black--text caption mb-0">
                      ${item.companyName}
                    </p>
                  </div>
                </div>
              `;
          }
          return mentionChar + item.name;
        },
      },
      clipboard: {
        allowed: {
          tags: [
            "a",
            "b",
            "strong",
            "u",
            "s",
            "i",
            "p",
            "br",
            "ul",
            "ol",
            "li",
            "span",
          ],
          attributes: ["href", "rel", "target", "class"],
        },
        keepSelection: false,
        substituteBlockElements: true,
        magicPasteLinks: true,
        hooks: {
          uponSanitizeElement(node: any) {
            if (node instanceof Element)
              node.innerHTML = node.innerHTML.replace(/&nbsp;/g, "");
          },
        },
      },
    },
    placeholder: "",
  };
  async updateMentionList(item: any): Promise<void> {
    const mention = item.text.split("@");
    if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm)
      for (let element of document.getElementsByClassName("mention")) {
        element.addEventListener(
          "click",
          this.removeMention.bind(null, element.attributes.item(3)?.value)
        );
      }
    if (mention.length === 1) return;
    const audience = this.publicItems.find(
      (audienceType: any) => audienceType.name === this.currentPublic
    );
    const audienceType = audience?.id || "ALL";
    const payload = {
      nameFilter: searchWord,
      audienceType: audienceType,
      eventsId: this.currentEvent.reduce(
        (string: string, event: Event, index: number) => {
          if (index === this.currentEvent.length - 1)
            string += String(event.id);
          else if (event.id || event.id === 0) string += event.id + ",";
          return string;
        },
        ""
      ),
      communitiesId: this.currentCommunity.reduce(
        (string: string, community: Event, index: number) => {
          if (index === this.currentCommunity.length - 1)
            string += String(community.id);
          else if (community.id || community.id === 0)
            string += community.id + ",";
          return string;
        },
        ""
      ),
    };
    await this.withLoading(async () => {
      await this.requestUsersMark(payload).then((data: any) => {
        items = data;
        items.forEach((item: any) => {
          item.value = item.name;
        });
      });
    });
  }
  removeMention(event: any): void {
    for (let item of document.getElementsByClassName("mention")) {
      if (item.attributes.item(3)?.value === event) item.remove();
    }
  }
}
</script>
<style lang="sass">
.quill-editor-error
  border: 2px solid var(--v-redAlert-base) !important
.quill-editor
  .ql-blank, .ql-editor
    min-height: 240px
  .ql-tooltip, .ql-editing
    left: 0 !important
iframe.ql-video
  height: 300px !important
  width: 100% !important
.mention
  background-color: var(--v-purple40-base) !important
  color: var(--v-purple100-base) !important
  border-radius: 4px !important
  padding: 2px !important
.ql-mention-list
  padding: 0 !important
.mention-border
  border-radius: 50px !important
  height: 40px !important
  width: 40px !important
  min-width: 40px !important
  max-width:  40px !important
  &__avatar-blue
  border: 2px solid var(--v-bluePartner-base) !important
  &__avatar-black
    border: 2px solid var(--v-black-base) !important
  &__avatar-red
    border: 2px solid var(--v-redExecutive-base) !important
  &__avatar-green
    border: 2px solid var(--v-greenCLevel-base) !important
</style>
