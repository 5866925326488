import { api } from "@/api";
import { AxiosResponse } from "axios";
import {
  PayloadAdvertStatus,
  PayloadStatusDenunciation,
  PayloadNewInterest,
  PayloadNewInterestApprove,
  PayloadDeleteSuggestion,
  PayloadAdvert,
  PayloadChart,
  PayloadChartFourNetwork,
  PayloadExportChartExclusive,
  PayloadExportChartFourNetwork,
  PayloadExportChartLounge,
  PayloadPermissionUser,
} from "../types";
class API {
  static getAdvertPending(payload: PayloadAdvert): Promise<AxiosResponse> {
    return api.get("/advertisement/pending", { params: payload });
  }
  static getAdvertApproved(payload: PayloadAdvert): Promise<AxiosResponse> {
    return api.get("/advertisement/approved", { params: payload });
  }
  static getAdvertDisapproved(payload: PayloadAdvert): Promise<AxiosResponse> {
    return api.get("/advertisement/disapproved", { params: payload });
  }
  static getAdvertExcluded(payload: PayloadAdvert): Promise<AxiosResponse> {
    return api.get("/advertisement/excluded", { params: payload });
  }
  static putAdvertStatus(payload: PayloadAdvertStatus): Promise<AxiosResponse> {
    return api.put("/advertisement/deliberate", payload);
  }
  static getDenunciationAdverts(): Promise<AxiosResponse> {
    return api.get("/denunciation/adverts");
  }
  static getDenunciationComments(): Promise<AxiosResponse> {
    return api.get("/denunciation/comments");
  }
  static getDenunciationPosts(): Promise<AxiosResponse> {
    return api.get("/denunciation/posts");
  }
  static getInactivationUsers(): Promise<AxiosResponse> {
    return api.get("/inactivation/users");
  }
  static getInactivationComments(payload: any): Promise<AxiosResponse> {
    return api.get("/inactivation/comments", { params: payload });
  }
  static getInactivationPosts(payload: any): Promise<AxiosResponse> {
    return api.get("/inactivation/posts", { params: payload });
  }
  static postReactivateUser(payload: number): Promise<AxiosResponse> {
    return api.get(`/inactivation/user/reactivate/${payload}`);
  }
  static getMetrics(): Promise<AxiosResponse> {
    return api.get("/dashboard");
  }
  static getRanking(payload: any): Promise<AxiosResponse> {
    return api.get("/dashboard/user-summary/interactions", { params: payload });
  }
  static putStatusDenunciation(
    payload: PayloadStatusDenunciation
  ): Promise<AxiosResponse> {
    return api.put("/denunciation/deliberate", payload);
  }
  static getPostUser(payload: any): Promise<AxiosResponse> {
    return api.get("/post/feed-total-posts", { params: payload });
  }
  static getCallToActionPost(payload: any): Promise<AxiosResponse> {
    return api.get(`/post/${payload.id}/get-call-to-actions`, {
      params: payload.params,
    });
  }
  static getInteractionPost(payload: any): Promise<AxiosResponse> {
    return api.get(`/post/${payload.id}/get-interactions`, {
      params: payload.params,
    });
  }
  static getMetricsCompany(): Promise<AxiosResponse> {
    return api.get("/dashboard/fournetwork");
  }
  static getRankingCompany(payload: any): Promise<AxiosResponse> {
    return api.get("/dashboard/fournetwork/user-summary/interactions", {
      params: payload,
    });
  }
  static getAdvertDeleted(): Promise<AxiosResponse> {
    return api.get("/post/list-excluded");
  }
  static postNewInterest(
    payload: [PayloadNewInterest]
  ): Promise<AxiosResponse> {
    return api.post("/interest/insert", payload);
  }
  static getFileMetrics(payload: number): Promise<AxiosResponse> {
    return api.get(`/file/post-metrics/${payload}`);
  }
  static getFileInteraction(payload: number): Promise<AxiosResponse> {
    return api.get(`/file/post-interactions/${payload}?dateFilter=YEAR`);
  }
  static getFileInterestExclusive(): Promise<AxiosResponse> {
    return api.get("/file/interests/exclusive");
  }
  static getFileMostInterestExclusive(): Promise<AxiosResponse> {
    return api.get("/file/most-posted-interests/exclusive");
  }
  static getFileMostInterestClevelsExclusive(): Promise<AxiosResponse> {
    return api.get("/file/most-posted-interests-clevels/exclusive");
  }
  static getFileInteractedInterestExclusive(): Promise<AxiosResponse> {
    return api.get("/file/interacted-interests/exclusive");
  }
  static getFileInterestCompany(): Promise<AxiosResponse> {
    return api.get("/file/interests/fournetwork");
  }
  static getFileMostInterestCompany(): Promise<AxiosResponse> {
    return api.get("/file/most-posted-interests/fournetwork");
  }
  static getFileInteractedInterestCompany(): Promise<AxiosResponse> {
    return api.get("/file/interacted-interests/fournetwork");
  }
  static getFileInteractedInterestCompanyExclusive(): Promise<AxiosResponse> {
    return api.get("/file/most-add-interests-executives/exclusive");
  }
  static getFileInteractedInterestClevelsExclusive(): Promise<AxiosResponse> {
    return api.get("/file/most-add-interests-clevels/exclusive");
  }
  static getFileInteractedHobbiesCompanyClevelsExclusive(): Promise<AxiosResponse> {
    return api.get("/file/most-add-hobby/exclusive");
  }
  static getUserClevel(payload: any): Promise<AxiosResponse> {
    return api.get("/user/list-all", { params: payload });
  }
  static getUserMarketing(payload: any): Promise<AxiosResponse> {
    return api.get("/user/list-all-marketing", { params: payload });
  }
  static putUpdateProfile(payload: string): Promise<AxiosResponse> {
    return api.put(`/user/update-status/${payload}`);
  }
  static getInterestHobby(payload: any): Promise<AxiosResponse> {
    return api.get("/interest/list-with-counter", { params: payload });
  }
  static getSuggestionInterestHobby(payload: any): Promise<AxiosResponse> {
    return api.get("/interest/suggest", { params: payload });
  }
  static putUpdateInterestHobby(payload: any): Promise<AxiosResponse> {
    return api.put("/interest/update", payload);
  }
  static postNewInterestApprove(
    payload: PayloadNewInterestApprove
  ): Promise<AxiosResponse> {
    return api.post("/interest/approve", payload);
  }
  static getDeleteSuggestion(
    payload: PayloadDeleteSuggestion
  ): Promise<AxiosResponse> {
    return api.get("/interest/exclude", { params: payload });
  }
  static putStatusInterestHobby(payload: string): Promise<AxiosResponse> {
    return api.put(`/interest/change-status${payload}`);
  }
  static getPostSchedule(payload: any): Promise<AxiosResponse> {
    return api.get("/post/api/v2/agendados", { params: payload });
  }
  static getCategories(): Promise<AxiosResponse> {
    return api.get("/interest/categories");
  }
  static getCommunities(payload: any): Promise<AxiosResponse> {
    return api.get("/community/moderation", { params: payload.params });
  }
  static getCommunityMembers(payload: any): Promise<AxiosResponse> {
    return api.get(`community/${payload.id}/users`, { params: payload.params });
  }
  static getCommunity(payload: any): Promise<AxiosResponse> {
    return api.get(`community/${payload}`);
  }
  static putCommunity(payload: any): Promise<AxiosResponse> {
    return api.put("/community", payload);
  }
  static getCommunityQuota(payload: any): Promise<AxiosResponse> {
    return api.get(`community/${payload}/cotas`);
  }
  static postCommunityQuota(payload: any): Promise<AxiosResponse> {
    return api.post(`community/${payload.id}/cota`, payload.params);
  }
  static deleteCommunityQuota(payload: any): Promise<AxiosResponse> {
    return api.put(`community/${payload.id}/cota`, payload.params);
  }
  static getAssignedBenefit(payload: any): Promise<AxiosResponse> {
    return api.get(`profile/${payload}/assigned-benefit`);
  }
  static postAssignedBenefit(payload: any): Promise<AxiosResponse> {
    return api.post(`profile/${payload.id}/assigned-benefit`, payload);
  }
  static getFixedList(payload: any): Promise<AxiosResponse> {
    return api.get("/post/fixed-list", { params: payload });
  }
  static postFixedList(payload: any): Promise<AxiosResponse> {
    return api.post(`/post/${payload.id}/fixed-list`, payload.params);
  }
  static getChartExclusive(payload: PayloadChart): Promise<AxiosResponse> {
    return api.get("/post-report/reach", { params: payload });
  }
  static getChartFourNetwork(
    payload: PayloadChartFourNetwork
  ): Promise<AxiosResponse> {
    return api.get("/post-report/engagement", { params: payload });
  }
  static getDebates(payload: any): Promise<AxiosResponse> {
    return api.get("/debate/moderation", { params: payload });
  }
  static putStatusDebate(payload: any): Promise<AxiosResponse> {
    return api.put("/debate/status", payload);
  }
  static getFixedTags(payload: any): Promise<AxiosResponse> {
    return api.get("/fixed-tag", { params: payload });
  }
  static postFixedTag(payload: any): Promise<AxiosResponse> {
    return api.post("/fixed-tag", payload);
  }
  static putFixedTag(payload: any): Promise<AxiosResponse> {
    return api.put("/fixed-tag", payload);
  }
  static getFixedTag(payload: any): Promise<AxiosResponse> {
    return api.get(`/fixed-tag/${payload}`, payload);
  }
  static deleteFixedTag(payload: any): Promise<AxiosResponse> {
    return api.delete(`/fixed-tag/${payload}`, payload);
  }
  static getViewsFixedTag(payload: any): Promise<AxiosResponse> {
    return api.get(`/fixed-tag/total-interactions/${payload}`, payload);
  }
  static putCompanyClick(payload: any): Promise<AxiosResponse> {
    return api.put(
      `/fixed-tag/company-click/${payload.fixedTagId}/${payload.companyId}/${payload.place}`,
      payload
    );
  }
  static getMetricsLounge(payload: any): Promise<AxiosResponse> {
    return api.get(`/dashboard/community/${payload.communityId}`, payload);
  }
  static getRankingLounge(payload: any): Promise<AxiosResponse> {
    return api.get(
      `/dashboard/community/${payload.communityId}/user-summary/interactions`,
      {
        params: payload,
      }
    );
  }
  static getPostReportLounge(payload: any): Promise<AxiosResponse> {
    return api.get(`/post-report/reach/${payload.communityId}`, {
      params: payload,
    });
  }
  static getInactivationDebate(payload: any): Promise<AxiosResponse> {
    return api.get("/inactivation/debate", { params: payload });
  }
  static getExportChartExclusive(
    payload: PayloadExportChartExclusive
  ): Promise<AxiosResponse> {
    return api.get("/post-report/export/reach", { params: payload });
  }
  static getExportChartFourNetwork(
    payload: PayloadExportChartFourNetwork
  ): Promise<AxiosResponse> {
    return api.get("/post-report/export/engagement", { params: payload });
  }
  static getExportChartLounge(
    payload: PayloadExportChartLounge
  ): Promise<AxiosResponse> {
    return api.get(`/post-report/export/reach/community/${payload.id}`, {
      params: payload.params,
    });
  }
  static getPermissionsUser(payload: string): Promise<AxiosResponse> {
    return api.get(`/permission/profile/${payload}`);
  }
  static postPermissionsUser(
    payload: PayloadPermissionUser
  ): Promise<AxiosResponse> {
    return api.post("/permission", payload);
  }
  static getPostColab(payload: any): Promise<AxiosResponse> {
    return api.get("/post/feed-total-posts/colabs", { params: payload });
  }
}

export default API;
