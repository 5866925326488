import Vue from "vue";
import App from "./views/App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./plugins/fragment";
import "./plugins/facade";
import "./plugins/quill";
import "./plugins/video";
import "./plugins/zoom";
import i18n from "./plugins/i18n";
import "./main.sass";
// Adding Layouts
import "@/components/base";
import "@/layouts";
// Adding Modules
import "@/modules/chat";
import "@/modules/notification";
import "@/modules/post";
import "@/modules/profile";
import "@/modules/readingList";
import "@/modules/search";
import "@/modules/settings";
import "@/modules/timeline";
import "@/modules/community";
import "@/modules/roundTable";
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
