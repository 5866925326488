<template>
  <v-tabs
    v-bind="[$attrs, $props]"
    color="purple100"
    slider-color="purple100"
    class="base-tab__tabs"
    v-on="$listeners"
  >
    <slot />
  </v-tabs>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
@Component
export default class BaseTab extends Vue {}
</script>
<style lang="sass">
.base-tab__tabs
  .v-slide-group__content, .v-tabs-bar__content
    @media(min-width: 900px)
      justify-content: center
  .v-window-item
    @media(min-width: 900px)
      justify-content: center
      display: flex
  .v-tab
    font-size: 12px
    font-weight: 400
    text-transform: none !important
    background-color: var(--v-white-base) !important
    color: var(--v-gray90-base) !important
    width: 50%
    letter-spacing: 0 !important
  .v-tabs-bar
    background-color: var(--v-white-base) !important
  .v-tab--active
    font-weight: 700 !important
    font-size: 12px
    text-transform: none !important
    background-color: var(--v-white-base) !important
    color: var(--v-purple100-base) !important
  .v-slide-group__prev--disabled
    display: none !important
.theme--light.v-tabs .v-tab--active:hover::before, .theme--light.v-tabs .v-tab--active::before
  opacity: 0 !important
.theme--light.v-tabs .v-tab:hover::before
  opacity: 0 !important
</style>
