<template>
  <v-footer app inset fixed class="pa-4 base-footer" :class="color">
    <div :class="limit">
      <slot />
    </div>
  </v-footer>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class BaseFooter extends Vue {
  @Prop({ default: "white" }) color: string;
  @Prop({ default: "max-content-width" }) limit: string;
}
</script>
<style lang="sass">
.base-footer
  border-top: 1px solid var(--v-gray15-base) !important
  left: 0px !important
</style>
