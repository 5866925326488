<template>
  <v-btn v-on="$listeners" v-bind="defaultConfigs">
    <slot />
  </v-btn>
</template>
<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
@Component
export default class BaseButton extends Vue {
  get defaultConfigs(): any {
    return Object.assign(
      {
        dense: true,
        ripple: false,
      },
      {
        ...this.$attrs,
        ...this.$props,
      }
    );
  }
}
</script>
<style lang="sass">
.v-btn
  text-transform: none !important
  border-radius: 16px !important
  box-shadow: none !important
  font-size: 12px !important
  font-weight: 700 !important
  letter-spacing: 0 !important
  white-space: normal !important
  @media(min-width: 900px)
    font-size: 14px !important
.v-btn::before
  background-color: transparent
.v-btn:not(.v-btn--round).v-size--default
  min-height: 40px
  height: auto !important
</style>
