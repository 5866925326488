<template>
  <div>
    <label
      :class="
        'body-2 font-weight-bold gray70--text d-inline-flex ' + labelPosition
      "
      :for="idElement"
      v-if="labelText"
    >
      {{ labelText }}
    </label>
    <div
      class="d-inline-flex base-form-input__eye caption black--text pt-1"
      v-if="eye"
    >
      <label class="pr-1">
        <b> {{ $t("base.baseFromInputShow") }} </b>
        {{ $t("base.baseFormInputProfile") }}
      </label>
      <BaseButton text class="base-form-input__button" @click="changeEye">
        <img :src="imageButton" />
      </BaseButton>
    </div>
    <v-text-field
      v-on="$listeners"
      v-bind="defaultConfigs"
      :id="idElement"
      :class="classInput"
    />
  </div>
</template>
<script lang="ts">
const defaultProp = {
  outlined: true,
  class: "mt-2 caption",
  dense: true,
  "background-color": "white",
};
import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class BaseFormInput extends Vue {
  @Prop(String) idElement: string;
  @Prop(String) labelText: string;
  @Prop({ default: "justify-start" }) labelPosition: string;
  @Prop({ default: false }) eye: boolean;
  @Prop(Boolean) eyeValue: boolean;
  @Prop({ default: "base-form-input" }) classInput: string;
  activeEye = false;
  get imageButton(): any {
    return this.eyeValue
      ? require("@/assets/globeOn.svg")
      : require("@/assets/globeOff.svg");
  }
  get defaultConfigs(): any {
    return {
      ...this.$attrs,
      ...this.$props,
      ...defaultProp,
    };
  }
  changeEye(): void {
    this.$emit("update", !this.eyeValue);
  }
}
</script>
<style lang="sass">
.base-form-input__
  &eye
    float: right
  &button
    min-width: 20px !important
    height: 14px !important
    padding: 0 !important
  &no-error
    .v-text-field__details
      display: none !important
</style>
