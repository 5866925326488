import App from "@/views/App.vue";

export default [
  {
    path: "",
    name: "settings",
    component: App,
    redirect: { name: "settingsHome" },
    children: [
      {
        path: "/configuracoes/:id?",
        name: "settingsHome",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Settings.vue"),
        meta: {
          displayName: "Configurações - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/configuracoes/postagem/:id",
        name: "settingsMetricsPost",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/MetricsSelectedPost.vue"
          ),
        meta: {
          displayName: "Métricas postagem - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/configuracoes/comunidade/:id",
        name: "settingsCommunity",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Community.vue"),
        meta: {
          displayName: "Lounge - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/configuracoes/cota-comunidade/:id",
        name: "settingsQuotaCommunity",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/QuotaCommunity.vue"),
        meta: {
          displayName: "Cota Lounge - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/configuracoes/postagem-colab/:id",
        name: "settingsMetricsColab",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/MetricsSelectedColab.vue"
          ),
        meta: {
          displayName: "Métricas colab - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/configuracoes/fixar-tag",
        name: "settingsNewFixTag",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/NewFixTag.vue"),
        meta: {
          displayName: "Fixar tag - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/configuracoes/fixar-tag/:id",
        name: "settingsEditFixTag",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/EditFixTag.vue"),
        meta: {
          displayName: "Editar tag fixada - Exclusive Lounge",
          requiresAuth: true,
        },
      },
    ],
  },
];
