import Vue from "vue";
import VueRouter from "vue-router";
import { TokenService } from "@/api/storage";
import { initialize } from "@/api";
import Store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home/:user/:language?",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Home.vue"),
    meta: {
      displayName: "Home - Exclusive Lounge",
      requiresAuth: false,
    },
  },
  {
    path: "/pagina-nao-encontrada",
    name: "notFound",
    alias: "/",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NotFound.vue"),
    meta: {
      displayName: "404 - Exclusive Lounge",
      requiresAuth: false,
    },
  },
  {
    path: "/pagina-sem-autenticacao",
    name: "notAuth",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NotAuth.vue"),
    meta: {
      displayName: "404 - Exclusive Lounge",
      requiresAuth: false,
    },
  },
  {
    path: "/onboarding/:user/:language?",
    name: "onboarding",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Onboarding.vue"),
    meta: {
      displayName: "Onboarding - Exclusive Lounge",
      requiresAuth: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    next("/pagina-nao-encontrada");
  } else if (to?.meta?.requiresAuth) {
    // redirect to login if the user to try acess any other route without login
    if (!TokenService.getToken()) {
      Store.dispatch("updateRouteAfterLogin", to);
      next({ name: "loginPage" });
    } else {
      initialize(TokenService.getToken());
      const isReturn =
        (to.name === "settingsHome" && from.name?.includes("settings")) ||
        ([
          "searchHome",
          "timelineHome",
          "readingListHome",
          "profileCLevel",
          "profileMarketing",
          "profileHome",
        ].includes(`${to.name}`) &&
          [
            "profileCLevel",
            "profileMarketing",
            "timelinePost",
            "communityHome",
            "searchTagId",
            "roundTableItems",
            "newPostRoundTable",
            "communityMembersHome",
          ].includes(`${from.name}`) &&
          (Store.state.lastItemsPost.length > 0 ||
            Store.state.lastItemsUser.length > 0));
      Store.dispatch("updateReturn", isReturn);
      if (to.name === "settingsHome" && from.name?.includes("settings"))
        Store.dispatch("updateLastRoute", from.name);
      if (from.name === "searchHome") Store.dispatch("updateFilter", "");
      if (from.name !== null && !isReturn && to.name !== "timelinePost") {
        TokenService.saveTab("0");
      }
      next();
    }
  } else {
    next();
  }
});

export default router;
