import App from "@/views/App.vue";

export default [
  {
    path: "/novo-post",
    name: "newPost",
    component: App,
    redirect: { name: "newPostHome" },
    children: [
      {
        path: "/novo-post",
        name: "newPostHome",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/NewPost.vue"),
        meta: {
          displayName: "Novo Post - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/editar-post/:id",
        name: "editPost",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/EditPost.vue"),
        meta: {
          displayName: "Novo Post - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/novo-anuncio",
        name: "newAdvert",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/NewAdvert.vue"),
        meta: {
          displayName: "Novo Anúncio - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/editar-anuncio/:id",
        name: "editAdvert",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/EditAdvert.vue"),
        meta: {
          displayName: "Editar Anúncio - Exclusive Lounge",
          requiresAuth: true,
        },
      },
    ],
  },
];
