import App from "@/views/App.vue";

export default [
  {
    path: "/timeline",
    name: "timeline",
    component: App,
    redirect: { name: "timelineHome" },
    children: [
      {
        path: "/timeline/:id?",
        name: "timelineHome",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Timeline.vue"),
        meta: {
          displayName: "Timeline - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/postagem/:id",
        name: "timelinePost",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/CurrentPost.vue"),
        meta: {
          displayName: "Postagem - Exclusive Lounge",
          requiresAuth: true,
        },
      },
    ],
  },
];
