<template>
  <div class="base_upload_button__div">
    <v-file-input
      v-bind="defaultConfigs"
      v-model="addedFiles"
      :multiple="multiple"
      class="base_upload_button__button"
      prepend-icon=""
      @change="updateFiles"
    />
    <slot />
  </div>
</template>

<script lang="ts">
import FileUpload from "vue-upload-component";
import { CustomFileUpload } from "@/types";
import { Component, Vue, Prop } from "vue-property-decorator";
import _ from "lodash";
import Bowser from "bowser";
@Component({
  components: { FileUpload },
})
export default class BaseUploadButton extends Vue {
  @Prop(String) idName: string;
  @Prop({ default: false }) dropFiles: boolean;
  @Prop(String) textButton: string;
  @Prop({ default: "" }) backgroundPhoto: string;
  @Prop({ default: 100 }) backgroundHeight: number;
  @Prop({ default: false }) big: boolean;
  @Prop({ default: false }) optional: boolean;
  @Prop({ default: true }) multiple: boolean;
  headers = {
    "X-Csrf-Token": "xxxx",
  };
  dataToken = {
    _csrf_token: "xxxxxx",
  };
  addedFiles = [];
  get defaultConfigs() {
    let result = Bowser.getParser(window.navigator.userAgent);
    const acceptItems =
      result.getBrowser().name === "Safari"
        ? "image/*, video/*"
        : "image/*, video/*, capture=camera";
    return Object.assign(
      {
        ref: "upload",
        "add-index": false,
        accept: acceptItems,
        "put-action": "/upload/put",
        directory: false,
        headers: this.headers,
        data: this.dataToken,
        drop: this.dropFiles,
        "input-id": this.idName,
        "post-action": "/upload/post",
      },
      {
        ...this.$attrs,
        ...this.$props,
      }
    );
  }
  updateFiles(value: any): void {
    if (_.isNull(value)) return;
    if (_.isArray(value)) {
      value.forEach((item: CustomFileUpload) => {
        this.inputFilter(item);
      });
      this.$emit("input", value);
      this.addedFiles = [];
    } else {
      this.inputFilter(value);
      this.$emit("input", [value]);
      this.addedFiles = [];
    }
  }
  inputFilter(file: any) {
    file.blob = "";
    const URL = window.URL || window.webkitURL;
    if (URL && URL.createObjectURL) file.blob = URL.createObjectURL(file);
  }
}
</script>
<style lang="sass">
.base_upload_button__
  &button
    width: 100%
    position: absolute
    opacity: 0
    z-index: 99
    cursor: pointer
    padding: 0
    margin: 0
    height: 100%
    .v-input__control
      height: 100%
      .v-input__slot
        min-height: 100%
        cursor: pointer
        .v-text-field__slot
          height: 100%
  &div
    position: relative
.file-uploads.file-uploads-html5 label
  cursor: pointer
</style>
