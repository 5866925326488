import { api } from "@/api";
import { PayloadRepost } from "@/types";
import { AxiosResponse } from "axios";
class API {
  static postNew(payload: any): Promise<AxiosResponse> {
    return api.post("/post", payload);
  }
  static getEvents(): Promise<AxiosResponse> {
    return api.get("/event/list");
  }
  static getCategories(): Promise<AxiosResponse> {
    return api.get("/post/categories");
  }
  static postNewMartekingPost(payload: any): Promise<AxiosResponse> {
    return api.post("/post/marketing-post", payload);
  }
  static getQuota(): Promise<AxiosResponse> {
    return api.get("/cota");
  }
  static putMartekingPost(payload: any): Promise<AxiosResponse> {
    return api.put("/post/marketing-post", payload);
  }
  static getUsersMark(payload: any): Promise<AxiosResponse> {
    return api.get("/profile/markable-users", { params: payload });
  }
  static postRepost(payload: PayloadRepost): Promise<AxiosResponse> {
    return api.post("/post/repost", payload);
  }
  static postWhitePapers(payload: any): Promise<AxiosResponse> {
    return api.post("/post/white-paper", payload);
  }
  static putWhitePapers(payload: any): Promise<AxiosResponse> {
    return api.put("/post/white-paper", payload);
  }
  static putRepost(payload: PayloadRepost): Promise<AxiosResponse> {
    return api.put("/post/repost", payload);
  }
  static postSuccessCase(payload: any): Promise<AxiosResponse> {
    return api.post("/post/success-case", payload);
  }
  static putSuccessCase(payload: any): Promise<AxiosResponse> {
    return api.put("/post/success-case", payload);
  }
  static getActiveEvents(): Promise<AxiosResponse> {
    return api.get("/event/list/active");
  }
  static postJournal(payload: any): Promise<AxiosResponse> {
    return api.post("/post/journal", payload);
  }
  static putJournal(payload: any): Promise<AxiosResponse> {
    return api.put("/post/journal", payload);
  }
  static postTransmission(payload: any): Promise<AxiosResponse> {
    return api.post("/post/transmission", payload);
  }
  static putTransmission(payload: any): Promise<AxiosResponse> {
    return api.put("/post/transmission", payload);
  }
}

export default API;
