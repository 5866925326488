import App from "@/views/App.vue";

export default [
  {
    path: "/meu-perfil",
    name: "profile",
    component: App,
    redirect: { name: "profileHome" },
    children: [
      {
        path: "",
        name: "profileHome",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/Profile.vue"),
        meta: {
          displayName: "Meu perfil - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/perfil/:id",
        name: "profileCLevel",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/ProfileCLevel.vue"),
        meta: {
          displayName: "Perfil C-level - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/perfil-marketing/:id",
        name: "profileMarketing",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/ProfileMarketing.vue"
          ),
        meta: {
          displayName: "Perfil Empresa - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/meu-perfil/editar-informacoes",
        name: "profileEditInfo",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/EditProfile.vue"),
        meta: {
          displayName: "Editar Informações - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/meu-perfil/novo-card/:id",
        name: "profileNewCard",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/NewCard.vue"),
        meta: {
          displayName: "Novo card - Exclusive Lounge",
          requiresAuth: true,
        },
      },
      {
        path: "/meu-perfil/editar-card/:id/",
        name: "profileEditCard",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/EditCard.vue"),
        meta: {
          displayName: "Novo card - Exclusive Lounge",
          requiresAuth: true,
        },
      },
    ],
  },
];
