<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :search="search"
    v-bind="[$attrs, $props]"
    item-class="pa-10 red"
    :footer-props="{
      showFirstLastPage: true,
      firstIcon: 'fa-solid fa-angles-left',
      lastIcon: 'fa-solid fa-angles-right',
    }"
    v-on="$listeners"
  >
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
    <slot />
  </v-data-table>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class BaseDataTable extends Vue {
  @Prop(Array) headers: any;
  @Prop(Array) items: any;
  @Prop(String) search: string;
}
</script>
<style lang="sass">
.base__datatable.v-data-table.theme--light
  background: var(--v-white-base)
  border: 1px solid var(--v-gray15-base) !important
  border-radius: 15px !important
  thead tr th
    font-weight: 700
    font-size: 12px
    color: var(--v-gray70-base) !important
    padding: 8px 16px !important
    border: none !important
    text-align: center !important
  tbody tr td
    font-weight: 400
    font-size: 14px
    color: var(--v-black-base) !important
    padding: 8px 16px !important
    border: none !important
    text-align: center !important
  tbody tr td:nth-child(odd)
    @media(max-width: 600px)
      background: var(--v-white-base) !important
  tbody tr td:nth-child(even)
    @media(max-width: 600px)
      background: var(--v-gray30-base) !important
.table-metrics.v-data-table.theme--light
  background: var(--v-white-base)
  border: 1px solid var(--v-gray15-base) !important
  border-radius: 15px !important
  thead tr th
    font-weight: 700
    font-size: 12px
    color: var(--v-gray70-base) !important
    padding: 8px 16px !important
    border: none !important
    text-align: center !important
  thead tr th:last-child
    text-align: center !important
  tbody tr td
    font-weight: 400
    font-size: 14px
    color: var(--v-black-base) !important
    padding: 8px 16px !important
    border: none !important
    text-align: center !important
  tbody tr:nth-child(odd)
    @media(max-width: 600px)
      background: var(--v-white-base) !important
  tbody tr:nth-child(even)
    @media(max-width: 600px)
      background: var(--v-gray30-base) !important
.table-metrics .v-data-footer .v-data-footer__select
  display: none !important
.table-metrics .v-data-footer
  height: 58px !important
  justify-content: end
  .v-data-footer__icons-before, .v-data-footer__icons-after
    button:first-child
     margin-right: 5px
.v-data-table.table-debate .v-data-table__wrapper table
    border-spacing: 0 10px !important
.table-debate.v-data-table.theme--light
  background: transparent !important
  border: 1px solid var(--v-gray15-base) !important
  border-radius: 15px !important
  thead tr th
    font-weight: 700
    font-size: 12px
    color: var(--v-gray70-base) !important
    padding: 8px 16px !important
    border: none !important
    text-align: center !important
  thead tr th:first-child
    text-align: start !important
  tbody tr td
    background: white !important
    font-weight: 400
    font-size: 14px
    color: var(--v-black-base) !important
    padding: 8px 16px !important
    border: none !important
    text-align: center !important
  tbody tr td:first-child
    border-radius: 15px 0 0 15px
    text-align: start !important
    @media(max-width: 600px)
      border-radius: 15px 15px 0 0
  tbody tr td:last-child
    border-radius: 0 15px 15px 0
    @media(max-width: 600px)
      border-radius: 0 0 15px 15px
.v-data-table.table-debate .v-data-table__wrapper tbody tr.v-data-table__expanded__content
  box-shadow: none !important
.table-debate .v-data-footer .v-data-footer__select
  display: none !important
.table-debate .v-data-footer
  border-radius: 0 0 15px 15px !important
  background: white !important
  height: 58px !important
  justify-content: end
  .v-data-footer__icons-before, .v-data-footer__icons-after
    button:first-child
     margin-right: 5px
.table-fixed-companies.v-data-table.theme--light
  background: tranparent
  border-radius: 15px !important
  thead tr th
    display: none !important
  tbody tr td
    display: none !important
.table-fixed-companies .v-data-footer .v-data-footer__select
  display: none !important
.table-fixed-companies .v-data-footer
  height: 58px !important
  justify-content: end
  border-top: none !important
  .v-data-footer__icons-before, .v-data-footer__icons-after
    button:first-child
     margin-right: 5px
.v-data-table-header__icon
  padding: 0 4px !important
.v-data-table-header th
  white-space: nowrap
</style>
