import API from "../api";
import { PayloadWhitePaperItems, PayloadSuccessCase } from "../types";
export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async requestWhitePapers(
      context: any,
      payload: PayloadWhitePaperItems
    ): Promise<any> {
      const { data } = await API.getWhitePapers(payload);
      return data;
    },
    async requestSuccessCase(
      context: any,
      payload: PayloadSuccessCase
    ): Promise<any> {
      const { data } = await API.getSuccessCase(payload);
      return data;
    },
    async requestVisibilitySuccessCase(
      context: any,
      payload: any
    ): Promise<any> {
      const { data } = await API.putVisibilitySuccessCase(payload);
      return data;
    },
  },
  modules: {},
};
