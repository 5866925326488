<template>
  <v-btn
    :class="
      'd-flex fill-height elevation-0 py-3 px-3 justify-start ' + classButton
    "
    v-bind="[$attrs, $props]"
    v-on="$listeners"
    min-height="20px"
    width="100%"
  >
    <img :src="customIcon" />
    <p
      :class="'mb-0 ml-0 item__text subtitle-1 pl-3 ' + colorText"
      v-show="!mini"
    >
      {{ item.name }}
    </p>
  </v-btn>
</template>
<script lang="ts">
import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class SidebarItem extends Vue {
  @Prop(Object) item: any;
  @Prop({ default: false }) mini: boolean;
  @Prop({ default: false }) selected: boolean;
  get colorText(): string {
    return this.selected ? "purple100--text font-weight-bold" : "gray70--text";
  }
  get classButton(): string {
    return this.mini ? "button-sidebar-item" : "";
  }
  get customIcon(): any {
    return this.selected ? this.item.imageSelected : this.item.image;
  }
}
</script>
<style lang="sass" scoped>
.v-btn:not(.v-btn--text):not(.v-btn--outlined).v-btn--active:before
  opacity: 0 !important
.theme--light.v-btn--active:before
  opacity: 0 !important
.item
  &__text
    margin-left: 14px
    padding-top: 3px
.selected-item
  border-left: 2px solid var(--v-purple100-base) !important
.button-sidebar-item
  min-width: 50px !important
.icon-sidebar
  width: 21px !important
</style>
